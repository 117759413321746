import {
  ArrowBack,
  ChangeCircle,
  CheckCircleOutline,
  NotInterested,
  Save,
  Send,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { Can } from "context/permissionContenxt";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ApplicationActions = (props) => {
  const navigate = useNavigate();

  const {
    canEdit = false,
    canCancel = false,
    canSubmit = false,
    settings = {},
    handleReject = () => {},
    handleCancel = () => {},
    handleSave = () => {},
    handleSubmit = () => {},
    handleApprove = () => {},
    handlePartiallyApprove = () => {},
    handleApproveSave = () => {},
    handleBackToHome = () => {
      navigate("/home");
    },
    handleAmendment = () => {},
  } = props;
  const [disabledButtons, setDisabledButtons] = useState(false);

  const handlePressButton = async (actionHandler) => {
    setDisabledButtons(true);
    await actionHandler();
    setDisabledButtons(false);
  };

  return (
    <div className="buttons-container">
      <Button
        disabled={disabledButtons}
        sx={{ color: "#fefefe" }}
        startIcon={<ArrowBack />}
        color="secondary"
        variant="contained"
        onClick={handleBackToHome}
      >
        Επιστροφή στις αιτήσεις
      </Button>
      {canCancel && (
        <Button
          onClick={handleCancel}
          disabled={disabledButtons}
          startIcon={<NotInterested />}
          color="error"
          variant="contained"
        >
          Ακύρωση
        </Button>
      )}

      {canEdit && (
        <Button
          disabled={disabledButtons}
          hidden={!canEdit}
          onClick={() => handlePressButton(handleSave)}
          startIcon={<Save />}
          variant="contained"
        >
          Αποθήκευση
        </Button>
      )}
      {canSubmit && (
        <Button
          onClick={handleSubmit}
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<Send />}
          variant="contained"
          color="success"
        >
          {settings?.submit?.txt}
        </Button>
      )}

      {settings?.reject?.can && (
        <Button
          disabled={disabledButtons}
          startIcon={<NotInterested />}
          color="error"
          variant="contained"
          onClick={handleReject}
        >
          {settings?.reject?.txt}
        </Button>
      )}
      {settings?.attachments?.canApprove && (
        <Button
          disabled={disabledButtons}
          startIcon={<Save />}
          variant="contained"
          onClick={handleApproveSave}
        >
          Αποθήκευση
        </Button>
      )}
      {settings?.approve?.can && (
        <Button
          onClick={handleApprove}
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<CheckCircleOutline />}
          variant="contained"
          color="success"
        >
          {settings?.approve?.txt}
        </Button>
      )}
      {settings?.partiallyApprove?.can && (
        <Button
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<CheckCircleOutline />}
          variant="contained"
          onClick={handlePartiallyApprove}
          color="success"
        >
          {settings?.partiallyApprove?.txt}
        </Button>
      )}
      <Can I="Amendment" a="MHD_Application">
        <Button
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<ChangeCircle />}
          variant="contained"
          onClick={handleAmendment}
        >
          Μεταβολή κατάστασης
        </Button>
      </Can>
    </div>
  );
};

export default ApplicationActions;
