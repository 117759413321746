import { Box, Typography, Stack, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { UnfoldLess, UnfoldMore, Delete } from '@mui/icons-material';
import { DropArea, FileList, PreviewDialog, FileForm, ReviewActions } from './components';
import { forwardRef } from 'react';
import { FileInputHandle, FileInputProps } from './types';
import { useFileInput } from './hooks';
import { useTranslation } from 'react-i18next';

const FileInput = forwardRef<FileInputHandle, FileInputProps>((props, ref) => {
    const { t } = useTranslation();
    const { bundle, maxSize, maxFiles, aspectRatio, isReadOnly,canAdd, reviewMode, onRemove, fields,accept } = props;
    const {
        upload,
        addFiles,
        updateFile,
        removeFile,
        rejectFiles,
        updateStatus,
        openEdit,
        expandAll,
        collapseAll,
        listRef,
        gRef,
        editMode,
        setEditMode,
        selectedFile,
        setSelectedFile,
        methods
    } = useFileInput(props, ref);


    const getBundleComments = () => {
        if (!reviewMode && ["2",2].includes(bundle?.status))
            return (<Typography sx={{fontStyle: 'italic', display: bundle?.reviewComments ? "" :'none'}}><b style={{color:'green'}}>ΑΠΟΔΕΚΤΟ</b>: {bundle?.reviewComments}</Typography>)
        if (!reviewMode && ["3",3].includes(bundle?.status))
            return (<Typography sx={{fontStyle: 'italic', display: bundle?.reviewComments ? "" :'none'}}><b style={{color:'darkred'}}>ΜΗ ΑΠΟΔΕΚΤΟ</b>: {bundle?.reviewComments}</Typography>)

        return null
    }


    return (
        <>
            <Grid
                container
                ref={gRef}
                spacing={1}
                sx={{
                    background: '#fff',
                    boxShadow: 2,
                    borderRadius: 2,
                    p: 2
                }}
            >
                <Grid item xs={8}>
                    {fields && <FileForm fields={fields} methods={methods} isReadOnly={isReadOnly} defaultValues={bundle?.values} />}
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end" alignItems="flex-start">
                    {!isReadOnly && (
                        <Tooltip title={t('DeleteBundle')} placement="top">
                            <IconButton onClick={onRemove} sx={{ color: '#c42c0e' }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!isReadOnly && canAdd && <DropArea onFilesSelected={addFiles} onFilesRejected={rejectFiles} maxSize={maxSize} accept={accept} />}
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography>{maxSize ? `${t('MaxSize')}: ${(maxSize / 1024 / 1024).toFixed(2)} MB` : ''}</Typography>
                        <Stack direction="row" alignItems="center">
                            <Tooltip title={t('ExpandAll')} placement="top">
                                <IconButton onClick={expandAll}>
                                    <UnfoldMore fontSize="small" sx={{ color: '#387de7' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('CollapseAll')} placement="top">
                                <IconButton onClick={collapseAll}>
                                    <UnfoldLess fontSize="small" sx={{ color: '#387de7' }} />
                                </IconButton>
                            </Tooltip>
                            <Typography>
                                {bundle && bundle.files.length}
                                {!!maxFiles && `/${maxFiles} ` + t('Files')}
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <FileList
                        files={bundle ? bundle.files : []}
                        setFiles={() => console.log('setFiles')}
                        onRemove={removeFile}
                        setPreviewId={setSelectedFile}
                        aspectRatio={aspectRatio}
                        isReadOnly={isReadOnly}
                        onEdit={openEdit}
                        ref={listRef}
                    />
                    {!reviewMode && <Typography sx={{fontStyle: 'italic', display: bundle?.reviewComments ? "" :'none'}}>{getBundleComments()}</Typography>}
                    {reviewMode && (
                        <Grid item xs={12}>
                            <ReviewActions onUpdateStatus={updateStatus} defaultStatus={bundle.status} defaultValidationComments={bundle.reviewComments} bundle={bundle}/>
                        </Grid>
                    )}
                </Grid>

                <Grid item container xs={12} spacing={1} sx={{ display: 'none' }}>
                    {!isReadOnly && (
                        <Grid item container xs={12} justifyContent="flex-end">
                            <Button
                                onClick={upload}
                                variant="contained"
                                // todo fix this
                                // disabled={files.length === 0 || files.some((file) => file.loading)}
                                sx={{
                                    background: '#4d79bc',
                                    color: '#fff',
                                    ':hover': {
                                        background: '#79AEC9',
                                        color: '#fff'
                                    }
                                }}
                            >
                                {t('Save')}
                            </Button>
                        </Grid>
                    )}
                    
                </Grid>
            </Grid>
            <PreviewDialog
                open={selectedFile !== null}
                onClose={() => setSelectedFile(null)}
                file={
                    bundle?.files.find((file) => file.id === selectedFile) ?? {
                        id: '0',
                        // todo refactor
                        // compressed: false,
                        name: 'empty',
                        type: 'empty',
                        position: 0,
                        fileObject: new File([], 'empty'),
                        saved: false
                    }
                }
                onUpdate={updateFile}
                aspectRatio={aspectRatio}
                editMode={editMode}
                setEditMode={setEditMode}
                isReadOnly={isReadOnly}
            />
        </>
    );
});

export default FileInput;
