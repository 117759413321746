import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import EmployeesFormDialog from "./EmployeesFormDialog";
import { Button, Grid, IconButton } from "@mui/material";
import { Add, AttachFile, Delete, Edit } from "@mui/icons-material";
import { useForm, useFormContext } from "react-hook-form";
import FilePreviewer from "qubCommon/FilePreviewer";
import { useYupValidationResolver } from "hooks/useYupValidationResolver";
import { useEmployeeValidation } from "util/validations/employee-form";
import { v4 as uuidv4 } from "uuid";
import { deleteFile, getFiles, uploadFiles } from "apis/controllers/files";
import { downloadFile } from "util";

const EmployeesTable = ({
  employees,
  canAdd,
  canDelete,
  canEdit,
  canPartiallyEdit,
}) => {
  const [data, setData] = useState(employees);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [fileToPreview, setFileToPreview] = useState(null);
  const employeeValidation = useEmployeeValidation();
  const resolver = useYupValidationResolver(employeeValidation);
  const employeeForm = useForm({ resolver });
  const form = useFormContext();

  const handleCloseDialog = () => {
    employeeForm.reset({});
    setOpenDialog(false);
  };

  const fetchFiles = async () => {
    if (data?.length > 0) {
      const dataWithFiles = await Promise.all(
        data?.map(async (employee) => {
          const res = await getFiles({
            DataKey: "MHD_Employee",
            DataValue: null,
            RecordId: null,
            RecordGuid: employee?.id,
            ProjectTableId: 6,
          });
          let resFiles;
          if (res?.data?.[0]?.files?.[0]?.pages?.length > 0)
            resFiles = res?.data?.[0]?.files?.[0]?.pages;
          else resFiles = [];
          return {
            ...employee,
            files: [...resFiles],
          };
        })
      );
      setData(dataWithFiles);
    }
  };

  useEffect(() => {
    form.setValue("employees", null, { shouldDirty: false });
    form.setValue("employees.created", [], { shouldDirty: false });
    form.setValue("employees.updated", [], { shouldDirty: false });
    form.setValue("employees.deleted", [], { shouldDirty: false });
    fetchFiles();
  }, [employees]);

  const sortTable = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    let sortedData;
    if (["jobType"].includes(key)) {
      sortedData = [...data].sort((a, b) => {
        const aValue = a[key]?.name || "";
        const bValue = b[key]?.name || "";
        if (aValue < bValue) {
          return direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    } else {
      sortedData = [...data].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    setData(sortedData);
  };

  const getIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <i className="fas fa-sort-up"></i>;
      } else {
        return <i className="fas fa-sort-down"></i>;
      }
    } else {
      return <i className="fas fa-sort"></i>;
    }
  };

  const handleAddRow = async (employee, files) => {
    const bundleId = uuidv4();
    const formData = new FormData();
    formData.append("Form Description", "Files");

    for (let index = 0; index < files?.length; index++) {
      const file = files[index];
      formData.append(`Files[${index}].FileName`, file.name);
      formData.append(`Files[${index}].BundleId`, bundleId);
      formData.append(`Files[${index}].FileTypeId`, employee.fileType);
      formData.append(`Files[${index}].RowGuid`, file.rowGuid);
      formData.append(`Files[${index}].File`, file);
      file.inDb = true;
    }

    const res = await uploadFiles(formData);
    for (let index = 0; index < files?.length; index++) {
      const file = files[index];
      const match = res?.data?.find(
        (dbFile) => dbFile?.rowGuid === file?.rowGuid
      );
      file.id = match.id;
    }
    if (res?.status === "success" && files?.length > 0)
      employee.bundles = [bundleId];
    else employee.bundles = [];
    employee.files = files;

    setData((prev) => [...prev, { ...employee, position: prev?.length + 1 }]);
    setOpenDialog(false);
    employeeForm.reset({});
    const createdEmployees = form.getValues("employees.created") ?? [];
    form.setValue("employees.created", [...createdEmployees, employee], {
      shouldDirty: true,
    });
  };

  const handleBeginEdit = (employee) => {
    employeeForm.reset(employee);
    setOpenDialog(true);
  };

  const handleDeleteRow = (employee) => {
    if (
      window.confirm(
        `Είστε σίγουροι οτι θέλετε να διαγράψετε το μέλος #${employee?.position} της ομάδας`
      )
    ) {
      if (employee?.isNew) {
        const createdEmployees = form.getValues("employees.created") ?? [
          form.setValue(
            "employees.created",
            createdEmployees.filter((emp) => emp.id !== employee.id),
            { shouldDirty: true }
          ),
        ];
      } else {
        const updatedEmployees = form.getValues("employees.updated");
        form.setValue(
          "employees.updated",
          updatedEmployees.filter((ap) => ap.id !== employee.id),
          { shouldDirty: true }
        );
        const deletedEmployees = form.getValues("employees.deleted") ?? [];
        form.setValue(
          "employees.deleted",
          [...deletedEmployees, employee?.id],
          { shouldDirty: true }
        );
      }
      setData((prev) => {
        let returnData = prev;
        returnData = prev.filter((row) => row.id !== employee.id);
        return returnData.map((row) =>
          row?.position > employee?.position
            ? { ...row, position: row?.position - 1 }
            : row
        );
      });
    }
  };

  const handleEditRow = async (employee, files) => {
    const formData = new FormData();
    formData.append("Form Description", "Files");
    for (let index = 0; index < files?.length; index++) {
      const file = files[index];
      if (!file?.id && !file?.inDb) {
        if (!employee.bundleId) employee.bundleId = uuidv4();
        formData.append(`Files[${index}].FileName`, file.name);
        formData.append(`Files[${index}].BundleId`, employee.bundleId);
        formData.append(`Files[${index}].FileTypeId`, employee.fileType);
        formData.append(`Files[${index}].RowGuid`, file.rowGuid);
        formData.append(`Files[${index}].File`, file);
        file.inDb = true;
      }
    }
    const res = await uploadFiles(formData);
    for (let index = 0; index < files?.length; index++) {
      const file = files[index];
      const match = res?.data?.find(
        (dbFile) => dbFile?.rowGuid === file?.rowGuid
      );
      if (!file.id) file.id = match?.id;
    }

    if (res?.status === "success" && files?.length > 0) {
      employee.bundles = [employee.bundleId];
    } else employee.bundles = [];
    employee.files = files;
    setData((prev) =>
      prev.map((row) =>
        row.id === employee.id ? { ...row, ...employee } : row
      )
    );
    const editedEmployees = form
      .getValues("employees.updated")
      .filter((ap) => ap.id !== employee.id);

    if (employee?.isNew) {
      const createdEmployees =
        form
          .getValues("employees.created")
          ?.filter((ap) => ap.id !== employee.id) ?? [];
      form.setValue("employees.created", [...createdEmployees, employee], {
        shouldDirty: true,
      });
    } else
      form.setValue("employees.updated", [...editedEmployees, employee], {
        shouldDirty: true,
      });
    setOpenDialog(false);
    employeeForm.reset({});
  };

  const handlePreviewFile = async (file, newFile = false) => {
    if (newFile) setFileToPreview(file);
    else {
      const res = await downloadFile(file?.id, file?.type ?? file?.contentType);
      setFileToPreview(res);
    }
  };

  const handleDeleteFile = async (delFile, id, bundleId) => {
    const res = await deleteFile({
      fileId: delFile?.id,
      bundleId,
      recordGuid: id ?? null,
      projectTableId: 6,
    });

    const createdEmployees = form.getValues("employees.created");
    const updatedEmployees = form.getValues("employees.updated");

    form.setValue(
      "employees.created",
      createdEmployees.map((ap) =>
        ap.id === id ? { ...ap, bundles: [] } : ap
      ),
      { shouldDirty: true }
    );
    form.setValue(
      "employees.updated",
      updatedEmployees.map((ap) =>
        ap.id === id ? { ...ap, bundles: [] } : ap
      ),
      { shouldDirty: true }
    );
    setData((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            files: item.files.filter((file) => file.id !== delFile?.id),
          };
        }
        return item;
      })
    );
    return res?.status;
  };

  return (
    <>
      {fileToPreview && (
        <FilePreviewer
          file={fileToPreview}
          onClose={() => setFileToPreview(null)}
        />
      )}
      <EmployeesFormDialog
        open={openDialog}
        handleAddRow={handleAddRow}
        handleClose={handleCloseDialog}
        employeeForm={employeeForm}
        handleEditRow={handleEditRow}
        handlePreviewFile={handlePreviewFile}
        handleDeleteFile={handleDeleteFile}
        canEdit={canEdit}
        canPartiallyEdit={canPartiallyEdit}
      />

      {data?.length < 5 && canAdd && (
        <Grid container item xs={12} sx={{ paddingBottom: 2 }}>
          <Button
            sx={{ marginLeft: "auto" }}
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              employeeForm.reset();
              setOpenDialog(true);
            }}
          >
            Προσθήκη
          </Button>
        </Grid>
      )}
      <div style={{ width: "100%", overflowX: "auto" }}>
        <table className="application-input-table">
          <thead>
            <tr>
              <th align="center" onClick={() => sortTable("position")}>
                Α/Α {getIcon("position")}
              </th>
              <th onClick={() => sortTable("amka")}>ΑΜΚΑ {getIcon("amka")}</th>
              <th onClick={() => sortTable("afm")}>ΑΦΜ {getIcon("afm")}</th>
              <th onClick={() => sortTable("fullName")}>
                Ονοματεπώνυμο {getIcon("fullName")}
              </th>
              <th onClick={() => sortTable("jobType")}>
                Ιδιότητα {getIcon("jobType")}
              </th>
              <th onClick={() => sortTable("comments")}>
                Σχόλια {getIcon("comments")}
              </th>
              <th onClick={() => sortTable("capacity")}>
                Βιογραφικό {getIcon("capacity")}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr key={index}>
                <td align="center">{row?.position ?? ""}</td>
                <td>{row?.amka ?? ""}</td>
                <td>{row?.afm ?? ""}</td>
                <td>
                  {row?.lastName ?? ""} {row.firstName ?? ""}
                </td>
                <td>{row?.jobType?.name ?? ""}</td>
                <td>{row?.comments ?? ""}</td>
                <td align="center">
                  {row?.files?.map((file, index) => (
                    <p
                      key={index}
                      onClick={() => {
                        handlePreviewFile(file, !file?.id);
                      }}
                      style={{
                        color: "#387de7",
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: 3,
                      }}
                    >
                      {index + 1}
                      <AttachFile sx={{ fontSize: 20 }} />
                    </p>
                  ))}
                </td>
                <td>
                  <div className="action-buttons">
                    {(canEdit || canPartiallyEdit) && (
                      <button
                        onClick={() => handleBeginEdit(row)}
                        className="btn btn-edit-small"
                      >
                        <Edit fontSize="small" />
                      </button>
                    )}
                    {canDelete && (
                      <button
                        onClick={() => handleDeleteRow(row)}
                        className="btn btn-edit-small"
                      >
                        <Delete fontSize="small" />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeesTable;
