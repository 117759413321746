import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Skeleton,
} from "@mui/material";
import { CssTextField } from "qubCommon/Inputs";
import { getFiles } from "apis/controllers/files";
import { useParams } from "react-router-dom";
import {
  deleteBundle,
  deleteFile,
  downloadFile,
  formatFiles,
  uploadFiles,
} from "util";
import { FileProvider, useFileContext } from "context/FilesV2";
import { FileGroup } from "common/FileUpload";
import { useLoading } from "hooks/useLoading";
import { setLoading } from "store/loadingReducer";

const ConfirmDialog = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
  commentsOn = false,
  withFileUpload = false,
  fileDatakey = null,
  fileDataValue = null,
}) => {
  const [comments, setComments] = useState("");
  const [approveFiles, setApproveFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const { id } = useParams;

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  const fetchBundles = async () => {
    setLoadingFiles(true);
    try {
      const res = await getFiles({
        DataKey: fileDatakey,
        DataValue: fileDataValue,
        RecordId: null,
        RecordGuid: id,
        ProjectTableId: 4,
      });
      setApproveFiles(res?.data);
    } catch (e) {
      console.log(e);
    }
    setLoadingFiles(false);
  };

  useEffect(() => {
    if (withFileUpload) fetchBundles();
  }, [withFileUpload]);

  return (
    <FileProvider
      downloadFile={(fileId, type) => downloadFile(fileId, type)}
      uploadFiles={(fileGroups) => uploadFiles(fileGroups, null)}
      onDeleteBundle={async (bundleId) =>
        await deleteBundle(bundleId, 4, null, id, null)
      }
      onDeleteFile={async (fileId) => {
        await deleteFile(fileId, 4, null, id, null);
      }}
    >
      <Dialog
        open={open}
        disableBackdropClick
        fullWidth={commentsOn || withFileUpload}
      >
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
        <DialogContent size="xl">
          <Typography>{content}</Typography>
          {commentsOn && (
            <CssTextField
              minRows={6}
              multiline
              fullWidth
              value={comments}
              onChange={handleCommentsChange}
              fixedPadding={8}
              sx={{ marginTop: 4 }}
            />
          )}
          {withFileUpload && !loadingFiles && (
            <ApproveFileBundles
              comments={comments}
              files={approveFiles}
              onClose={handleClose}
              onConfirm={onConfirm}
            />
          )}
          {withFileUpload && loadingFiles && (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
        </DialogContent>
        {!withFileUpload && (
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="contained">
              Όχι
            </Button>
            <Button
              onClick={() => onConfirm(comments)}
              color="primary"
              variant="contained"
            >
              Ναι
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </FileProvider>
  );
};

const ApproveFileBundles = ({ files, onClose, onConfirm, comments }) => {
  const { fileGroups, control } = useFileContext();

  const handleClose = () => {
    control.initialize([]);

    onClose();
  };

  useEffect(() => {
    try {
      const formattedFiles = formatFiles(files);
      if (formattedFiles !== undefined) {
        control.initialize(formattedFiles);
      } else {
        control.initialize([]);
      }
    } catch (error) {
      console.error("error", error);
    }
  }, [files]);

  const handleConfirm = async () => {
    await control.uploadFileGroups();
    const bundleIds = fileGroups.reduce((acc, group) => {
      return [
        ...acc,
        ...group.bundles
          .filter((bundle) => bundle?.files?.length > 0) // Filter bundles where files length is greater than 0
          .map((bundle) => bundle.id),
      ];
    }, []);
    onConfirm(bundleIds, comments);
  };

  return (
    <>
      {fileGroups?.map((group, index) => {
        return (
          <FileGroup
            reviewMode={false}
            isReadOnly={false}
            key={group.id}
            id={group.id}
            title={group?.title}
            maxSize={2097152}
            maxFiles={5}
            maxBundles={group?.maxBundles}
            fields={group.fields}
            bundles={group?.bundles}
            templates={group?.templates}
          />
        );
      })}

      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Όχι
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Ναι
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmDialog;
