import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import TextInput from "src/qubCommon/Inputs/TextInput";
import Dropdown from "src/qubCommon/Inputs/Dropdown";
import { FormProvider } from "react-hook-form";
import FormCheckbox from "src/qubCommon/Inputs/Checkbox";
import { useLookup } from "src/context/lookupContext";
import { v4 as uuidv4 } from "uuid";
import { deleteFile, getFiles } from "apis/controllers/files";
import { Add, AttachFile, Delete } from "@mui/icons-material";
import AlertBanner from "../AlertBanner";

const ListFileItem = ({
  file,
  handlePreview,
  id,
  bundleId,
  handleDeleteAndRemoveFile,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #4d79bc",

        borderRadius: 2,
        px: 1,
        mb: 1,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <AttachFile
            fontSize="small"
            sx={{
              color: "#387de7",
            }}
          />
          <Typography
            onClick={() => {
              if (file.isNew) handlePreview(file, true);
              else handlePreview(file);
            }}
            sx={{
              cursor: "pointer",
              color: "#387de7",
              ":hover": {
                fontWeight: "bold",
              },
            }}
          >
            <u>{file.name ?? file?.origFName}</u>
          </Typography>
        </Stack>

        <IconButton
          onClick={() => {
            handleDeleteAndRemoveFile(file, id, bundleId);
          }}
        >
          <Delete sx={{ color: "#d12639" }} />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default function ApartmentFormDialog(props) {
  const {
    open,
    handleClose,
    apartmentForm,
    handleAddRow,
    handleEditRow,
    handlePreviewFile,
    handleDeleteFile = () => {},
    canEdit,
    canPartiallyEdit,
  } = props;
  const { lookups } = useLookup();
  const [files, setFiles] = React.useState([]);

  const editId = apartmentForm?.getValues("id");
  const bundleId = apartmentForm?.getValues("bundleId");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getPrefectures = () => {
    const prefs = lookups?.prefectures?.filter(
      (pref) => pref?.regionId === apartmentForm.watch("regionId")
    );
    return prefs;
  };

  const getMunicipalities = () => {
    const muns = lookups?.municipalities?.filter(
      (mun) => mun?.prefectureId === apartmentForm.watch("prefectureId")
    );
    return muns;
  };

  const findLookupObject = (lookupTable, value, fieldName) => {
    const selectedObject = lookupTable?.find((record) => record?.id === value);
    apartmentForm.setValue(fieldName, selectedObject.name);
  };

  const getGenders = () => {
    if (apartmentForm.watch("ageRange.id") === 1)
      return lookups?.gender.filter((gender) => gender.id !== 3);
    return lookups?.gender;
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];
      selectedFile.isNew = true;
      selectedFile.rowGuid = uuidv4();
      const validTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!validTypes.includes(selectedFile.type)) {
        alert("Λάθος τύπος αρχείου. Παρακαλώ επιλέξτε εικόνα ή pdf.");
        return;
      }
    }
    setFiles((prev) => [...prev, ...selectedFiles]);
  };

  const fetchFiles = async () => {
    const res = await getFiles({
      DataKey: "MHD_Appartment",
      DataValue: null,
      RecordId: null,
      RecordGuid: editId,
      ProjectTableId: 5,
    });
    const pages = res?.data?.[0]?.files?.[0]?.pages || [];
    const pageIds = pages.map((page) => page.id); // Extracting ids from pages
    let newFiles;
    try {
      newFiles = apartmentForm.getValues("files").filter((ap) => ap.isNew);
      newFiles = newFiles.filter((file) => !pageIds.includes(file.id));
    } catch {
      newFiles = [];
    }

    setFiles([...pages, ...newFiles]);
    apartmentForm.setValue("fileType", res?.data?.[0]?.type);
    apartmentForm.setValue(
      "bundleId",
      res?.data?.[0]?.files?.[0]?.bundleId ??
        apartmentForm.getValues("bundles")?.[0]
    );
  };

  React.useEffect(() => {
    if (open) fetchFiles();
  }, [open]);

  const handleDeleteAndRemoveFile = async (file, id, bundleId) => {
    if (
      window.confirm(
        `Είστε σίγουροι οτι θέλετε να διαγράψετε το αρχείο ${
          file?.name ?? file?.origFName
        }`
      )
    ) {
      if (file?.id) {
        const res = await handleDeleteFile(file, id, bundleId);
        if (res === "success")
          setFiles((prev) => prev.filter((obj) => file?.id !== obj.id));
      } else {
        setFiles((prev) => prev.filter((obj) => file?.rowGuid !== obj.rowGuid));
      }
    }
  };

  return (
    <FormProvider {...apartmentForm}>
      <Dialog
        onClose={() => {
          setFiles([]);
          handleClose();
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontSize: "24px" }}
          id="customized-dialog-title"
        >
          Στοιχεία Διαμερίσματος / Ομάδας Στόχου
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent
          sx={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <AlertBanner
            info={{
              message: ` <div>Η ${
                editId ? "Τροποποίηση" : "Προσθήκη"
              } θα πραγματοποιηθεί μετά την αποθήκευση της αίτησης.</div> `,
              cssClass: "info",
            }}
          />
          <div className="divider-with-text">Διεύθυνση / Χαρακτηριστικά</div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Dropdown
                name="regionId"
                options={lookups?.regions}
                label="Περιφέρεια"
                labelName="name"
                valueName="id"
                disabled={!canEdit && !canPartiallyEdit}
                changeFn={(e) => {
                  apartmentForm.setValue("prefectureId", null);
                  apartmentForm.setValue("prefecture", null);
                  apartmentForm.setValue("municipalityId", null);
                  apartmentForm.setValue("municipality", null);
                  findLookupObject(lookups?.regions, e.target.value, "region");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Dropdown
                name="prefectureId"
                options={getPrefectures()}
                label="Περιφέρεακή Ενότητα"
                labelName="name"
                valueName="id"
                disabled={!canEdit && !canPartiallyEdit}
                changeFn={(e) => {
                  apartmentForm.setValue("municipalityId", null);
                  apartmentForm.setValue("municipality", null);
                  findLookupObject(
                    getPrefectures(),
                    e.target.value,
                    "prefecture"
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Dropdown
                name="municipalityId"
                options={getMunicipalities()}
                label="Δήμος"
                labelName="name"
                valueName="id"
                disabled={!canEdit && !canPartiallyEdit}
                changeFn={(e) =>
                  findLookupObject(
                    getMunicipalities(),
                    e.target.value,
                    "municipality"
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                defaultFromProps={false}
                disabled={!canEdit && !canPartiallyEdit}
                name="city"
                label="Πόλη"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                defaultFromProps={false}
                name="street"
                label="Οδός"
                disabled={!canEdit && !canPartiallyEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                disabled={!canEdit && !canPartiallyEdit}
                defaultFromProps={false}
                name="streetNumber"
                label="Αριθμός"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextInput
                disabled={!canEdit && !canPartiallyEdit}
                defaultFromProps={false}
                name="zip"
                label="TK"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                disabled={!canEdit && !canPartiallyEdit}
                defaultFromProps={false}
                name="floor"
                label="Όροφος"
                type="number"
                step="1"
                rules={{
                  setValueAs: (value) => (value === "" ? null : value), // Convert empty string to null
                }}
                blurFn={(e) => {
                  if (e.target.value === "")
                    apartmentForm.setValue("floor", null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  else
                    apartmentForm.setValue("floor", parseInt(e.target.value));
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                defaultFromProps={false}
                name="m2"
                rules={{
                  setValueAs: (value) => (value === "" ? null : value), // Convert empty string to null
                }}
                disabled={!canEdit && !canPartiallyEdit}
                label="Επιφάνεια (τ.μ.)"
                type="number"
                blurFn={(e) => {
                  if (e.target.value === "") apartmentForm.setValue("m2", null);
                  else apartmentForm.setValue("m2", parseInt(e.target.value));
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                disabled={!canEdit && !canPartiallyEdit}
                name="ownershipType.id"
                options={lookups?.ownershipType}
                label="Είδος"
                labelName="name"
                valueName="id"
                changeFn={(e) => {
                  findLookupObject(
                    lookups?.ownershipType,
                    e.target.value,
                    "ownershipType.name"
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography mb={2} sx={{ fontStyle: "italic" }}>
                Σύμβαση μίσθωσης ή παραχώρησης ή αποδεικτικό ιδιοκτησίας:
              </Typography>
              {(canEdit || canPartiallyEdit) && (
                <Stack
                  direction="row"
                  gap={2}
                  mb={2}
                  sx={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<Add />}
                  >
                    Προσθήκη Αρχείων
                    <input
                      type="file"
                      multiple
                      hidden
                      onChange={handleFileChange}
                      accept="image/jpeg,image/png,application/pdf"
                    />
                  </Button>
                </Stack>
              )}
              {files?.length > 0 &&
                files.map((file, index) => (
                  <ListFileItem
                    key={index}
                    file={file}
                    handlePreview={handlePreviewFile}
                    id={editId}
                    bundleId={bundleId}
                    handleDeleteAndRemoveFile={handleDeleteAndRemoveFile}
                  />
                ))}
            </Grid>
          </Grid>
          <div className="divider-with-text">Λεπτομέρειες Ομάδας Στόχου</div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Dropdown
                isReadOnly={!canEdit}
                name="ageRange.id"
                options={lookups?.ageRange}
                label="Ηλικιακή Ομάδα"
                labelName="name"
                valueName="id"
                changeFn={(e) => {
                  findLookupObject(
                    lookups?.ageRange,
                    e.target.value,
                    "ageRange.name"
                  );
                  apartmentForm.setValue("gender.id", null);
                  apartmentForm.setValue("gender.name", null);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Dropdown
                isReadOnly={!canEdit}
                name="gender.id"
                options={getGenders()}
                label="Φύλο"
                labelName="name"
                valueName="id"
                changeFn={(e) => {
                  findLookupObject(
                    lookups?.gender,
                    e.target.value,
                    "gender.name"
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              container
              justifyContent="center"
              alignContent="flex-end"
            >
              <FormCheckbox
                disabled={!canEdit}
                name="isAmea.id"
                label="ΑμεΑ"
                changeFn={(e) => {
                  apartmentForm.setValue(
                    "isAmea.name",
                    e.target.checked ? "ΝΑΙ" : "ΟΧΙ"
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                disabled={!canEdit}
                defaultFromProps={false}
                type="number"
                name="capacity"
                label="Δυναμικότητα Διαμερίσματος"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ width: "90%", mx: "auto" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "#fefefe", marginRight: "auto" }}
            autoFocus
            onClick={handleClose}
          >
            Πίσω
          </Button>
          <Button
            sx={{}}
            onClick={apartmentForm.handleSubmit((data) => {
              const Apartment = apartmentForm.getValues();
              apartmentForm.setValue(
                "address",
                `${Apartment?.street ?? ""} ${
                  Apartment?.streetNumber ?? ""
                } Όροφος: ${Apartment?.floor ? `${Apartment?.floor},` : ""}  ${
                  Apartment?.city ?? ""
                } ΤΚ: ${Apartment?.zip ?? ""}, ΔΗΜΟΣ: ${
                  Apartment?.municipality ?? ""
                }`
              );
              if (!editId) {
                apartmentForm.setValue("id", uuidv4());
                apartmentForm.setValue("isNew", true);
              }
              editId
                ? handleEditRow(apartmentForm.getValues(), files)
                : handleAddRow(apartmentForm.getValues(), files);
            })}
            variant="contained"
            autoFocus
          >
            {editId ? "Τροποποίηση" : "Προσθήκη"}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
