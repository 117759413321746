import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useModal,
  ModalImages,
  ModalButtons,
} from "src/qubCommon/context/modalContext";
import { useLoading } from "src/hooks/useLoading";
import { getData } from "src/apis/controllers/apiEntities";

const useToolbarProps = (props) => {
  const { webPageKey, newTitle, newUrl, hideAddNew } = props;
  const [initColumns, setColumns] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [csvFiles, setCsvFiles] = useState([]);
  const [excelFiles, setExcelFiles] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [critParams, setCritParams] = useState(null);
  const [status, setStatus] = useState(false);
  const [showRowHeader, setShowRowHeader] = useState(true);
  const [showColHeaderGroup, setShowColHeaderGroup] = useState(false);
  const [cellCssMapping, setCellCssMaping] = useState([]);
  const [pageSize, setPageSize] = useState([]);

  // criteria Ref is used to save fetched Data in order to use them in Criteria Component
  const criteriaRef = useRef([]);
  const critId = searchParams.get("critId");
  const { openModal } = useModal();

  const { setLoading } = useLoading();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const res = await getData({
          dataKey: "Qub-ExplorerToolbarData-Get",
          parameters: { webPageKey, critId: critId ?? null },
        });
        if (res?.status !== "error") {
          const gridColumns = res?.data?.gridColumns;
          const pdf = res?.data?.rptPdf;
          const csv = res?.data?.rptCsv;
          const excel = res?.data?.rptExcel;

          // if (!searchParams.get("critId")) {
          //   setSearchParams({ critId: res?.data?.critId });
          // }

          setShowRowHeader(res?.data?.showRowHeader ?? true);
          setShowColHeaderGroup(res?.data?.showColHeaderGroup ?? false);
          setPageSize(res?.data?.pageSize ?? []);
          setCritParams(res?.data?.critParams);
          setCellCssMaping(res?.data?.cellCssMapping ?? []);
          setPdfFiles(pdf ?? []);
          setCsvFiles(csv ?? []);
          setExcelFiles(
            excel
              ? [...excel, { rptId: "default", rptTitle: "Προεπιλογή" }]
              : [{ rptId: "default", rptTitle: "Προεπιλογή" }]
          );

          setColumns(gridColumns ?? []);
          criteriaRef.current.critValuesList = res?.data?.critValuesList;
          criteriaRef.current.critFldsSearch = res?.data?.critFldsSearch;
          setStatus(true);
        } else {
          openModal({
            title: "Σφάλμα",
            message: "Δεν βρέθηκαν οι απαραίτητες παράμετροι.",
            imageType: ModalImages.Error,
            buttons: ModalButtons.Ok,
            callback: (command) => {},
          });
        }
      } catch (e) {
        setLoading(false);
        window.alert(
          "Παρουσιάστηκε σφάλμα, αν το πρόβλημα επιμένει αποσυνδεθείτε και συνδεθείτε ξανά."
        );
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  return {
    initColumns,
    pdfFiles,
    csvFiles,
    excelFiles,
    criteriaRef,
    critParams,
    status,
    newTitle,
    newUrl,
    hideAddNew,
    showRowHeader,
    showColHeaderGroup,
    pageSize,
    cellCssMapping,
  };
};

export default useToolbarProps;
